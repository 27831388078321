.right-object-flow {
    width: 700px;
    height: 350px;

    margin-left: 20px;
}

.object-flow {
    width: 700px;
    height: 350px;
}
