.display-view {
    width: 1920px;
    height: 1080px;
    box-sizing: border-box;

    background-image: url("../assets/pictures/bg.png");
}

/* 以下是头部样式 */
.view-header {
    width: 1920px;
    height: 80px;
}

/* 以下是内容的样式 */
.view-content {
    width: 1920px;
    height: 1000px;
    box-sizing: border-box;

    display: flex;
    flex-direction: row;
}

.content-left {
    width: 400px;
    height: 1000px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

.content-middle {
    width: 800px;
    height: 1000px;
    /*background: pink;*/
}

.content-right {
    width: 820px;
    height: 1000px;
    /*background: white;*/

    display: flex;
    flex-direction: column;
}
