.left-scroll-board {
    width: 350px;
    height: 300px;
    max-width: 350px;
    max-height: 300px;

    margin-left: 20px;
    margin-top: 30px;

    display: flex;
    flex-direction: column;
}

.left-board-title {
    display: flex;
    flex-direction: row;
}

.title-content {
    margin-left: 20px;

    font-weight: bold;
    font-size: 30px;
    color: white;
}


.scroll-board {
    margin-top: 10px;
}
