.right-document-flow {
    height: 570px;
    width: 700px;
    margin-left: 20px;
    margin-top: 20px;
}

.right-document-flow-title {
    height: 40px;
    display: flex;
    flex-direction: row;
}

.right-document-flow-title-content {
    margin-left: 20px;

    font-weight: bold;
    font-size: 30px;
    color: white;
}

.right-document-flow-header {
    height: 80px;
    margin-top: 10px;

    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.right-document-flow-name {
    margin-left: 20px;
    margin-bottom: 5px;

    font-weight: bold;
    font-size: 20px;
    color: white;
}

.document-flow {
    height: 450px;
    width: 700px;
}
