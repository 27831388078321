.header-all {
    width: 1920px;
    height: 80px;
    box-sizing: border-box;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    pointer-events: none;
}

.header-center-decoration {
    width: 40%;
    height: 65px;
    margin-top: 15px;
}

.header-left-decoration {
    width: 25%;
    height: 65px;
    margin-top: 15px;
}

.header-right-decoration {
    width: 25%;
    height: 65px;
    margin-top: 15px;
}

.center-title {
    position: absolute;
    font-size: 30px;
    font-weight: bold;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    color: white;
}
