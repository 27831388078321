.document-info {
    width: 640px;

    position: relative;
    left: 40px;
    bottom: 50px;
}

.document-info-title {
    margin-bottom: 10px;

    font-weight: bold;
    font-size: 20px;
    color: white;
}

.column-header-class {
    padding: 0 !important;
    background-color: rgba(0, 1, 10, 1) !important;
    color: white !important;
}

.info-row-class {
    padding: 0 !important;
    background: rgba(0, 1, 10, 1);
    color: white;
}

