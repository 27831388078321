.middle-chart {
    height: 450px;
    margin-left: 30px;
    margin-top: 20px;
    position: relative;
}

.middle-chart-title {
    position: absolute;
    right: 70px;
    text-align: right;
}

.middle-chart-name {
    color:white;
    top: 10px;
    font-weight: bold;
    font-size: 30px;
}

.middle-chart-content {
    width: 720px;
    height: 450px;
}
