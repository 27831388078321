.document-flow-left {
  /* width: 950px; */
  margin-left: 5%;
  height: 600px;
  flex: 1;
}

.object-flow-right {
  /* width: 9500px; */
  margin-right: 5%;
  height: 600px;
  flex: 1;
  /*background: pink;*/
}

.document-table {
  width: 1920px;
  height: 400px;
  align-items:center;
  align-content: center;
  /*background: white;*/
}

.flow-content{
  width: 1920px;
  height: 600px;
  box-sizing: border-box;

  display: flex;
  flex-direction: row;
}